import { useTranslate } from '@tolgee/react';
import useDevice from 'hooks/DesktopDevice.hook';
import { Box, Stack } from 'styles';
import { TRANSLATION_KEYS } from 'utils/constants';
import FormContactUs from './FormContactUs.component';

const ContactUs = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { isDesktop } = useDevice();

	return (
		<Stack
			height="100%"
			direction="row"
			gap={isDesktop ? '268px' : '63px'}
			pb={isDesktop ? '60px' : '30px'}
			pt={isDesktop ? '36.5px' : '26px'}
		>
			<Box width="100%" overflow="auto" paddingRight="5px">
				{/* <Stack direction="row" width="100%" alignItems="flex-start" mb={4}>
					<Stack direction="column" gap="30px" width="100%">
						<Stack gap="10px" width={300} sx={{ textOverflow: 'ellipsis' }} mr="10px">
							<Typography variant="body">
								{tClientLabels('contact-us.information.locationName')}
							</Typography>
							<Typography noWrap variant="bodyLink">
								{profile?.location}
							</Typography>
						</Stack>
						<Stack gap="10px">
							<Typography variant="body">
								{tClientLabels('contact-us.information.name')}
							</Typography>
							<Typography variant="bodyLink">{profile?.name} </Typography>
						</Stack>
					</Stack>
					<Stack direction="column" gap="30px" width="100%">
						<Stack gap="10px">
							<Typography variant="body">
								{tClientLabels('contact-us.information.contactTelephone')}
							</Typography>
							<Typography variant="bodyLink">{locationFields.phoneNumber}</Typography>
						</Stack>
						<Stack gap="10px">
							<Typography variant="body">
								{tClientLabels('contact-us.information.email')}
							</Typography>
							<Typography variant="bodyLink">{locationFields.email}</Typography>
						</Stack>
					</Stack>
				</Stack>
				{/* <iframe
					title="iFrame-qualtrics"
					className="iFrame-custom"
					width="100%"
					id="iFrame-contact"
					loading="lazy"
					height="600px"
					src={`${QUALTRICS_URL}${getLocaleLanguage()}`}
				></iframe> */}
				<FormContactUs />
			</Box>
			{/* <Box justifySelf="flex-end" mt={isDesktop ? '88px' : '159.45px'}>
				{isDesktop ? (
					<ContactUsDesktop height="100%" />
				) : (
					<ContactUsTablet height="100%" />
				)}
			</Box> */}
		</Stack>
	);
};

export default ContactUs;
